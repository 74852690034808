import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ConclusionPage from "./pages/ConclusionPage/ConclusionPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import FormPage from "./pages/FormPage/FormPage";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <EventsPage />
        </Route>
        <Route exact path="/form">
          <FormPage />
        </Route>
        <Route exact path="/conclusion">
          <ConclusionPage />
        </Route>
      </Switch>
    </Router>
  );
}
