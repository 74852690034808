import React from "react";

import style from "./style.module.css";
//import { useHistory } from "react-router-dom";
import { Card, Image } from "semantic-ui-react";

import logo from "../../assets/Logo.png";
import baner from "../../assets/baner.jpg";
import { Link } from "react-router-dom";

export default function ConclusionPage() {
  return (
    <div className={style.page}>
      <div className={style.container}>
        <Card centered fluid>
          <Card.Content>
            <Card.Header>Inscrição eventos Visagio concluída</Card.Header>
            <Card.Meta>
              Sua inscrição para os eventos da Visagio foi concluída, fique
              atento à data e ao seu e-mail! 🚀💚
            </Card.Meta>
            <Card.Description>
              Venha fazer parte da plataforma
              <div>
                <Link
                  to={{ pathname: "https://visagio.gupy.io" }}
                  target="_blank"
                >
                  Aqui
                </Link>
              </div>
            </Card.Description>
          </Card.Content>
          <Image src={baner} wrapped ui={false} />
        </Card>

        <div className={style.divButton}>
          <Image src={logo} wrapped ui={false} />
        </div>
      </div>
    </div>
  );
}
