import React from "react";

import AppRouter from "./AppRouter";

export default function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}
