import React, { useEffect } from "react";

import style from "./style.module.css";
//import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Image,
  Input,
} from "semantic-ui-react";
import { useState } from "react";
import logo from "../../assets/Logo.png";
import baner from "../../assets/baner.jpg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Subscription } from "../../types/subscription";
import CandidateApi from "../../api/candidate";
import { Course } from "../../types/course";
import { University } from "../../types/university";
import { FirstContactOption } from "../../types/firstContactOption";
import { Degree } from "../../types/degree";

interface LocationState {
  events: Array<string>;
}

export default function FormPage() {
  const history = useHistory();
  const params = useLocation<LocationState>();
  let events: Array<string>;
  if (!params || !params.state) {
    history.push("/");
  } else {
    events = params.state.events;
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [courses, setCourses] = useState<Array<Course>>([]);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);

  const [universities, setUniversities] = useState<Array<University>>([]);
  const [selectedUniversity, setSelectedUniversity] =
    useState<University | null>(null);

  const [degrees, setDegrees] = useState<Array<Degree>>([]);
  const [selectedDegree, setSelectedDegree] = useState<Degree | null>(null);

  const [firstContactOptions, setFirstContactOptions] = useState<
    Array<FirstContactOption>
  >([]);
  const [selectedFirstContactOption, setSelectedFirstContactOption] =
    useState<FirstContactOption | null>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [policy, setPolicy] = useState(false);

  const save = async () => {
    setLoading(true);
    setError("");
    try {
      if (
        name === "" ||
        email === "" ||
        !policy ||
        selectedCourse?.name === "" ||
        selectedUniversity?.name === "" ||
        selectedDegree?.name === "" ||
        selectedFirstContactOption?.name === ""
      ) {
        setError("");
        setTimeout(() => {
          setError("Preencha todos os campos obrigatórios *");
        }, 100);
      } else {
        for (const e of events) {
          const subscription: Subscription = {
            name: name,
            email: email,
            eventId: e,
            firstContactOption: selectedFirstContactOption!.name,
            university: selectedUniversity!.name,
            course: selectedCourse!.name,
            privacyPolicy: policy,
          };
          if (!(await CandidateApi.saveSubscription(subscription))) {
            setError("Problema de conexão");
          }
        }
        if (error !== "Problema de conexão") {
          history.push("/conclusion");
        }

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError("Problema de conexão");
    }
    setLoading(false);
  };

  const getBaseInformation = async () => {
    try {
      const courses = await CandidateApi.getCourses();
      setCourses(courses);

      const universities = await CandidateApi.getUniversities();
      setUniversities(universities);

      const degrees = await CandidateApi.getDegreeLevel();
      setDegrees(degrees);

      const firstContactOptions = await CandidateApi.getFirstContactOptions();
      setFirstContactOptions(firstContactOptions);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBaseInformation();
  }, []);
  return (
    <div className={style.page}>
      <div className={style.container}>
        <Card centered fluid>
          <Card.Content>
            <Card.Header>Eventos Visagio</Card.Header>
            <Card.Meta>
              Faça aqui sua pré-inscrição para o processo seletivo da Visagio e
              vem pra plataforma 🚀💚
            </Card.Meta>
            <Card.Description>
              Venha fazer parte da plataforma
              <div>
                <Link
                  to={{ pathname: "https://visagio.gupy.io" }}
                  target="_blank"
                >
                  Aqui
                </Link>
              </div>
            </Card.Description>
          </Card.Content>
          <Image src={baner} wrapped ui={false} />
        </Card>

        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Card.Header>Nome *</Card.Header>
          </Card.Content>
          <Input
            className={style.input}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome"
          />
        </Card>

        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Card.Header>E-mail *</Card.Header>
          </Card.Content>
          <Input
            className={style.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
          />
        </Card>
        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Card.Header>Qual seu Curso? *</Card.Header>
            <Dropdown
              fluid
              className={style.input}
              placeholder="Curso"
              search
              selection
              onChange={(_, { value }) =>
                setSelectedCourse({ name: value ? value.toString() : "" })
              }
              options={courses.map((it) => {
                return { key: it.name, text: it.name, value: it.name };
              })}
            />
          </Card.Content>
        </Card>

        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Card.Header>Qual sua Universidade? *</Card.Header>
            <Dropdown
              fluid
              className={style.input}
              placeholder="Universidade"
              search
              selection
              onChange={(_, { value }) =>
                setSelectedUniversity({ name: value ? value.toString() : "" })
              }
              options={universities.map((it) => {
                return { key: it.name, text: it.name, value: it.name };
              })}
            />
          </Card.Content>
        </Card>

        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Card.Header>Qual seu nivel de formação? *</Card.Header>
            <Dropdown
              fluid
              className={style.input}
              placeholder="Formação"
              search
              selection
              onChange={(_, { value }) =>
                setSelectedDegree({ name: value ? value.toString() : "" })
              }
              options={degrees.map((it) => {
                return { key: it.name, text: it.name, value: it.name };
              })}
            />
          </Card.Content>
        </Card>

        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Card.Header>Como você descobriu sobre o evento? *</Card.Header>
            <Dropdown
              fluid
              className={style.input}
              placeholder=""
              search
              selection
              onChange={(_, { value }) =>
                setSelectedFirstContactOption({
                  name: value ? value.toString() : "",
                })
              }
              options={firstContactOptions.map((it) => {
                return { key: it.name, text: it.name, value: it.name };
              })}
            />
          </Card.Content>
        </Card>

        <Card color="teal" centered fluid>
          <Card.Content textAlign="left">
            <Checkbox
              label="Concorda com a Política de Privacidade "
              name="checkboxRadioGroup"
              checked={policy}
              onChange={() => {
                setPolicy(!policy);
              }}
            />
            <br />
            <Link
              to={{
                pathname: "https://visagio.com/politica-de-privacidade/",
              }}
              target="_blank"
            >
              Politica de Privacidade Visagio
            </Link>
          </Card.Content>
        </Card>
        <div className={style.divButton}>
          <label className={style.error}>{error}</label>
          <Button loading={loading} color="teal" onClick={save}>
            Enviar
          </Button>

          <Image src={logo} wrapped ui={false} />
        </div>
      </div>
    </div>
  );
}
