import api from ".";
import { Subscription } from "../types/subscription";
import { Course } from "../types/course";
import { Degree } from "../types/degree";
import { VisagioEvent } from "../types/visagioEvent";

export default class CandidateApi {
  static async saveSubscription(subscription: Subscription) {
    const res = await api.post("/saveSubscription", subscription);
    if (res.status === 200) {
      return true;
    }
    return false;
  }

  static async getCourses(): Promise<Array<Course>> {
    const res = await api.get("/getCourses");
    if (res.status === 200) {
      return res.data;
    }
    return [];
  }

  static async getUniversities(): Promise<Array<Course>> {
    const res = await api.get("/getUniversities");
    if (res.status === 200) {
      return res.data;
    }
    return [];
  }

  static async getFirstContactOptions(): Promise<Array<Course>> {
    const res = await api.get("/getFirstContactOptions");
    if (res.status === 200) {
      return res.data;
    }
    return [];
  }

  static async getDegreeLevel(): Promise<Array<Degree>> {
    const res = await api.get("/getDegreeLevel");
    if (res.status === 200) {
      return res.data;
    }
    return [];
  }

  static async getEvents(): Promise<Array<VisagioEvent>> {
    const res = await api.get("/getEvents");
    if (res.status === 200) {
      return res.data;
    }
    return [];
  }
}
