import axios from "axios";

const headers = {
  "Content-Type": "application/json;charset=utf-8",
  Accept: "application/json",
};

const api = axios.create({
  baseURL: "https://us-central1-visagio-talentos-plat.cloudfunctions.net",
  // baseURL: "http://localhost:5001/visagio-talentos-plat/us-central1",
  headers: headers,
  timeout: 20000,
});

export default api;
