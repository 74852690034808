import React, { useEffect } from "react";

import style from "./style.module.css";
//import { useHistory } from "react-router-dom";
import { Button, Card, Checkbox, Image, Loader } from "semantic-ui-react";
import { useState } from "react";
import logo from "../../assets/Logo.png";
import baner from "../../assets/baner.jpg";
import { Link, useHistory } from "react-router-dom";
import CandidateApi from "../../api/candidate";
import { VisagioEvent } from "../../types/visagioEvent";
import moment from "moment";

export default function EventsPage() {
  const history = useHistory();

  const [events, setEvents] = useState<Array<VisagioEvent>>([]);
  const selectedEventIds: Array<String> = [];

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    try {
      if (selectedEventIds.length > 0) {
        history.push({
          pathname: "/form",
          state: { events: selectedEventIds },
        });
      }
      setError("Escolha pelo menos um evento");
    } catch (err) {
      setLoading(false);
      setError("Problema de conexão");
    }
    setLoading(false);
  };

  const getBaseInformation = async () => {
    try {
      const e = await CandidateApi.getEvents();
      setEvents(e);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBaseInformation();
  }, []);
  return (
    <div className={style.page}>
      <div className={style.container}>
        <Card centered fluid>
          <Card.Content>
            <Card.Header>Eventos Visagio</Card.Header>
            <Card.Meta>
              Faça aqui inscrição para os eventos Visagio 🚀💚
            </Card.Meta>
            <Card.Description>
              Venha fazer parte da plataforma
              <div>
                <Link
                  to={{ pathname: "https://visagio.gupy.io" }}
                  target="_blank"
                >
                  Aqui
                </Link>
              </div>
            </Card.Description>
          </Card.Content>
          <Image src={baner} wrapped ui={false} />
        </Card>
        {events.length === 0 ? (
          <div className={style.loader}>
            <Loader active size="massive" />
          </div>
        ) : (
          events.map((e) => {
            return (
              <Card color="teal" centered fluid>
                <div className={style.cardContainer}>
                  <img alt="logo" className={style.cardImage} src={e.image} />
                  <div className={style.cardContent}>
                    <div className={style.cardTitle}>{e.name}</div>
                    <div className={style.cardDescription}>{e.description}</div>
                    <div className={style.cardDate}>{`Data: ${moment(
                      e.date
                    ).format("DD/MM/YYYY - HH:mm")}`}</div>
                    <div className={style.cardList}>{`Local: ${e.locale}`}</div>
                    <div className={style.cardList}>
                      {!e.price || e.price === 0
                        ? "Gratuito"
                        : `Preço: R$${e.price.toFixed(2)}`}
                    </div>
                    <div className={style.cardSubscribe}>
                      <Checkbox
                        label="Inscrever-se"
                        name="checkboxRadioGroup"
                        onChange={(_, data) => {
                          if (data.checked) {
                            if (selectedEventIds.indexOf(e.id) === -1) {
                              selectedEventIds.push(e.id);
                            }
                          } else {
                            const index = selectedEventIds.indexOf(e.id);
                            if (index > -1) {
                              selectedEventIds.splice(index, 1); // 2nd parameter means remove one item only
                            }
                          }
                          console.log(selectedEventIds);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            );
          })
        )}

        <div className={style.divButton}>
          <label className={style.error}>{error}</label>
          <Button loading={loading} color="teal" onClick={save}>
            Inscrever-se
          </Button>

          <Image src={logo} wrapped ui={false} />
        </div>
      </div>
    </div>
  );
}
